$font-primary: 'Open Sans',
sans-serif;
$font-secondary: arial,
helvetica,
sans-serif;
$color-primary: #fff;
html {
    -webkit-font-smoothing: antialiased;
}

body {
    /* Margin bottom by footer height */
    
    font-family: $font-primary;
    font-weight: 400;
    height: 100%;
}

body.landing {
    background-color: #ebebeb;
    background: -webkit-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -moz-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -o-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -ms-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: linear-gradient(100% 0%, #dedede, #f4f4f4);
    min-height: 100%;
    //background: url(../images/background_mini.jpg)
    //no-repeat center center fixed;
    //-webkit-background-size: cover;
    //-moz-background-size: cover;
    //-o-background-size: cover;
    //background-size: cover;
    //background-position: bottom center;
}

body.b-locator {
    background-color: #ebebeb;
    background: -webkit-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -moz-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -o-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -ms-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: linear-gradient(100% 0%, #dedede, #f4f4f4);
    min-height: 100%;
}

.navbar {
    margin-bottom: 0px;
}

.navbar-inverse {
    background-color: #fff;
    border-color: #fff;
}

.navbar-static-top {
    padding-left: 15px;
    height: 105px;
}

.navbar-brand {
    float: left;
    height: auto;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-static-top .navbar-brand .logo {
    margin-top: 30px;
    height: auto;
    width: 300px;
}

.slogan h1 {
    font-family: $font-secondary;
    font-size: 24px;
    color: #353435;
    float: right;
    line-height: 45px;
    margin-top: 31px;
    padding-right: 30px;
}

.logo-red {
    color: #0a4f9e;
}

.logo-blue {
    color: #e62332;
}

.cont-slogan {
    background-color: #fff;
    height: 50px;
    margin-top: 200px;
}

.cont-slogan h2 {
    font-family: $font-secondary;
    font-size: 20px;
    color: #353435;
    line-height: 50px;
    margin: 0px;
    padding: 0px;
}

.cont-start {
    background-color: #353435;
    height: 60px;
}

.landing-search {
    margin-top: 8px;
}

.glyphicon-search {
    font-size: 24px;
    float: left;
    margin-left: 10px;
    margin-right: 12px;
    margin-top: -2px;
    color: #d8d8d8;
}

.start {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    font-style: normal;
    line-height: 62px;
    height: 60px;
    padding-right: 20px;
    float: left;
}

.cont-search {
    background-color: #e62332;
    height: 45px;
}

.cont-search p {
    font-size: 15px;
    color: #fff;
    line-height: 45px;
    font-weight: 600;
}

.places {
    font-size: 13px;
    color: #fff;
    padding-left: 10px;
}

.places a {
    font-size: 13px;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
}

.places a:hover {
    color: #353435;
    text-decoration: none;
}

.share-text {
    float: left;
    font-size: 15px;
    color: #fff;
    position: absolute;
    top: 13px;
    left: 9px;
    cursor: pointer;
    display: block;
}

.at4-icon.aticon-compact,
.at4-icon.aticon-more,
.at4-icon.aticon-expanded,
.at4-icon.aticon-addthis {
    background: url(none) no-repeat left;
    visibility: hidden;
}

.at-share-tbx-element.addthis_32x32_style .at-share-btn,
.at-share-tbx-element.addthis_32x32_white_style .at-share-btn {
    max-width: 32px;
    max-height: 32px;
    opacity: 0;
}

.form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    margin-top: 8px;
    height: 44px;
}

.form-control {
    display: block;
    height: 34px;
    padding-top: 9px;
    font-size: 16px;
    line-height: 44px;
    color: #989898;
    background-color: #424242;
    background-image: none;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-inline .form-control.city {
    width: 640px;
    margin-right: 10px;
    display: block;
    line-height: 44px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    padding-top: 8px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 44px;
}

.btn-primary {
    color: #fff;
    background-color: #353435;
    border: none;
    height: 44px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #353435;
    border: none;
    height: 44px;
}

.btn-default {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    color: #989898;
    background-color: #424242;
    border: none;
    height: 44px;
    width: 260px;
    text-align: left;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #989898;
    background-color: #424242;
    border-color: #adadad;
    -webkit-box-shadow: none;
    width: 260px;
}

.multiselect-selected-text {
    float: left;
}

.fa-angle-down {
    margin-top: -1px;
    float: right;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.btn-group.open .fa-angle-down {
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.fa {
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#at15s.atm {
    margin-left: -120px;
}

.footer {
    position: relative;
    margin-top: 50px;
    bottom: 0;
    width: 100%;
    height: 115px;
    background-color: #2d2d2d;
}

body.b-locator .footer {
    margin-top: 40px;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 115px;
    background-color: #2d2d2d;
}

.footer-left {
    height: 115px;
    background-image: url(../images/footer-tyre.png);
    background-repeat: no-repeat;
    ;
    background-position: left center;
}

.footer-logo {
    width: 300px;
    margin-top: 35px;
}

.footer-right p {
    padding-top: 47px;
    font-size: 13px;
    color: #989898;
    font-weight: 600;
    letter-spacing: -1px;
    text-align: right;
    padding-right: 11px;
}

.footer-right p img {
    padding: 0px 5px 0px 5px;
}
/* Locator page styles */

.locator-marg {
    margin-top: 90px;
    padding-right: 0px;
}

.f-locator {
    padding-left: 10px;
    padding-right: 0px;
}

body.b-locator .form-inline .form-control.city {
    width: 664px;
}

.full-holder {

    padding-left: 10px;
    padding-right: 10px;
}

.tab-pane {
    padding-top: 10px;
    padding-bottom: 10px;
}
.map-holder {
    background-color: #d5212e;
       margin-left: -10px;
    margin-right: -10px;
           padding-left: 10px;
    padding-right: 10px;
}
.main-details-holder {
    background-color: #2e6fb5;
       margin-left: -10px;
    margin-right: -10px;
           padding-left: 10px;
    padding-right: 10px;
}
.no-pads {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;

}
.no-pads-second {

    padding-right: 0px;
    margin-right: -10px;

}
.location-links a {
    display: block;
    height: 60px;
    color:#fff;
    font-weight: 600;
    font-size: 18px;
    padding-top: 12px;
    padding-left: 27px;
    background-color: #113f7e;
    border-bottom: 1px solid #2e6fb5;

}
.location-links a:hover {
    text-decoration: none;
    background-color: #2e6fb5;

}
.number {
    font-size: 24px;
    font-weight: 400;
    display: block;
    height: 35px;
    width:35px;
    border-radius: 50%;
    border: 2px solid #4188c7;
    text-align: center;
    line-height: 30px;
    float: left;
    margin-right: 18px;
}
.comp-name {
    width:310px;
    display: block;
    float: left;
        line-height: 37px;
        font-size: 16px;
        font-weight: 600;
}
.miles {
    font-size: 16px;
    color:#a4d3f3;
    font-weight: 400;
        line-height: 37px;
}
.distance {
    font-size: 16px;
    color:#fff;
    font-weight: 600;
        line-height: 37px;
        background-image: url(../images/link-pointer-off.svg);
        background-position: left center;
        padding-left: 30px;
        background-repeat: no-repeat;
}
.location-links a:hover .distance {

        background-image: url(../images/link-pointer-on.svg);

}
.title-holder {
    padding-top: 12px;


}
.details-title {
    color:#fff;
    font-size: 18px;
    font-weight: 600;
    width:300px;
    display: block;
    float: left;
    margin-top: -4px;



}
.tel-num {
    font-size: 14px;
    font-weight: 400;
    color:#fff;
    padding-left: 25px;
    background-image: url(../images/tel.svg);
    background-position: left center;
    background-repeat: no-repeat;
        background-size: 17px 18px;
        padding-right: 25px;


 
}
a.email-dealer {
    font-size: 14px;
    font-weight: 400;
    color:#fff;
    padding-left: 25px;
    background-image: url(../images/email.svg);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 23px 14px;
     
}
.small-map-holder {
    padding-top: 18px;
 
}
.main-info {
    clear:both;
    padding-top: 24px;
    font-size: 12px;
    color:#a4d3f3;
    line-height: 20px;
    height: auto;

}
.tab-content > .tab-pane .main-info p {

    color:#a4d3f3;

}
.address-title {
    font-size: 14px;
    font-weight: 600;
    color:#FFF;
}
.main-info div {

    height: 100px;
    padding-left: 45px;
    padding-right: 20px;
}
.address {
    background-image: url(../images/detail-pointer.svg);
    background-size: 15px 24px;
    background-repeat: no-repeat;
    background-position: 20px 2px;
}
.opening {
    background-image: url(../images/detail-clock.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 16px 2px;
}
.directions {
    background-image: url(../images/detail-directions.svg);
    background-size: 19px 29px;
    background-repeat: no-repeat;
    background-position: 16px 2px;
}

.address, .opening {
    border-right: 1px solid #2462ab;
}

.hr {
    margin-top: 30px;
    border-bottom: 1px solid #2462ab;
    clear:both;
}
.icon-box{

    border-left: 1px solid #2462ab;
  
    text-align: center;
    box-sizing: border-box;
}
.slick-carousel {
    height: 40px;
}
.slick-carousel img {

    height:32px;
    width:auto;
    margin-left: auto;
    margin-right: auto;
}
.services-title {
    font-size:14px;
    font-weight: 600;
    color:#FFF;
    position: absolute;
    left:-80px;
    top:23px;
    margin-left: 18px;
}
.slider-controls-holder {
    margin-top: -15px;

    text-align: center;
    margin-left: 3px;
}
.slider-controls {
    height: 23px;
    background-color: #154e9c;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    color:#a4d3f3;
    width: auto;
    padding: 3px 22px 0px 22px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative;
    width:250px;
    font-weight: 600;
    text-transform: uppercase;
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    
    border-top: 12px solid #154e9c;
        margin-left: auto;
    margin-right: auto;

}
.cover-up {
    position: absolute;
    background-color: #fff;
    width:5px;
    height: 34px;
    top:-8px;
    left:76px;
    z-index: 11;
}

/* Competition Styles */
.competition {
    margin-top: 60px;
}

.top-panel {
    background-color: #ed1a2e;
    background-image: url('../uploads/competition-image.jpg');
    background-repeat: no-repeat;
    background-position: top right;
    height: 398px;
    position: relative;

    //&:after {
    //    content: '';
    //    z-index: 99;
    //    position: absolute;
    //    bottom: -25px;
    //    left: 130px;
    //    width: 0;
    //    height: 0;
    //    border-left: 25px solid transparent;
    //    border-right: 25px solid transparent;
    //    border-top: 25px solid #ed1a2e;
    //}

    .competition-text {

        padding-left: 25px;
        padding-top: 50px;


        p {
            font-family: $font-secondary;
            font-size: 18px;
            color: #fff;
            padding-bottom: 20px;
            margin: 0;

            &.big {
                font-size: 22px;
                line-height: 26px;
                padding-bottom: 40px;
            }

            &:last-child {
                font-size: 16px;
            }

            a {
                color: #fff;
                font-weight: bold;
                padding-bottom: 1px;
                border-bottom: 1px solid #fff;

                &:hover {
                    text-decoration: none;
                    border-bottom: 1px dotted #fff;
                }
            }
        }
    }
}

.bottom-panel {
    background-color: #353535;
    padding: 45px 0 10px 15px;
    height: 230px;

    .form-group {
        width: 230px;
        margin-right: 20px;
        float: left;

        .form-item.has-error input {
            border-color: #ed1a2e;
        }

        input {
            font-family: $font-primary;
            width: 100%;
            background: none;
            margin-bottom: 13px;
            border: 2px solid #4f4f4f;
            padding: 9px;
            font-size: 13px;
            color: #fff;
            border-radius: 5px;
            outline: none;
            -moz-transition: border-color 0.5s ease;
            -webkit-transition: border-color 0.5s ease;
            -o-transition: border-color 0.5s ease;
            transition: border-color 0.5s ease;

            &:active, &:focus {
                border-color: #1d6ec5;
            }
        }

        .underline-form-item input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            margin-bottom: 1px;
            padding: 5px;

            &.town-city {
                width: 133px;
                margin-right: 10px;
                float: left;
            }
            &.postcode {
                width: 87px;
                float: left;
            }
        }

        &.form-group-submit {
            width: 110px;
            margin-right: 0;

            input {
                background: none;
                color: #fff;
                border: 2px solid #1d6ec5;
                height: 93px;
                border-radius: 5px;
                -moz-transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;

                &:hover {
                    background-color: #1d6ec5;
                }
            }
        }
    }
}

.gt-link {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 18px;
    margin-top: 40px;
    a {
        color: #333;
    }
}
.form-group-new {
  
    clear:both;

}
.label-text {
    font-size:13px;
    padding-left: 20px;
    margin-top: 5px;
    float: left;
    font-weight: 400;
    color:#999999;
}
.label-text a {
    font-size:13px;
    color:#999999;
    border-bottom: 1px solid #999;
}
.label-text a:hover {
    border-bottom: 1px dotted #999;
    text-decoration: none;
}
.last-check {
    margin-left: 18px;
}
.denotes {
    float: right;
    font-size:13px;
    color:#999999;
    padding-right: 20px;
    padding-top: 15px;
}

.confirmation-holder {
    padding-left: 25px;
 
}
.confirmation-holder p {
    font-family: $font-secondary;

    color: #fff;
    padding-bottom: 0px;
    margin: 0;
    color:#fff;
}
.confirmation-holder p.small {
    font-family: $font-secondary;
    font-size: 14px;
    color: #fff;
    padding-bottom: 30px;
    margin: 0;
    color:#fff;
}

.confirmation-holder .big {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 20px;
 
}
.form-item {
    position: relative;

}
.tooltip-icon {
    position: absolute;
    top:9px;
    left: 89%;
    opacity: 1;

}
.tooltip.top .tooltip-inner {
    background-color:#4f4f4f;
    opacity: 1;
}
.tooltip.top .tooltip-arrow {
    border-top-color: #4f4f4f;
    opacity: 1;
}
.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
  

.modal-content {
    background-color: #353535;
    color: #999999;
}
.modal-body p {

    color: #999;
    font-size: 13px;
}
.modal-header {

border-bottom: none; 
padding-bottom: 0px;

}
.modal-header h4 {
    font-size: 18px;
    color:#c8c8c8;
    font-weight: 600;
}
.modal .close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .5;
}

    

/* Mobile */

@media (max-width: 767px) {

    body.landing {
    }
    .navbar {
        margin-bottom: 20px;
    }
    .navbar-static-top {
        padding: 0px;
        margin: 0px;
    }
    .cont-start {
        background-color: #fff;
        height: 50px;
        padding: 0px;
    }
    .cont-search {
        background-color: #fff;
        height: 500px;
    }
    .cont-start .container {
        padding: 0px;
        margin: 0px;
    }
    .cont-slogan {
        background-color: #fff;
        height: 70px;
        margin-top: -35px;
        text-align: center;
        padding: 0px;
    }
    .cont-slogan h2 {
        font-family: arial, helvetica, sans-serif;
        font-size: 15px;
        color: #353435;
        line-height: 50px;
        margin: 0px;
        padding: 0px;
        position: absolute;
        z-index: 1111;
        width: 100%;
        left: 0px;
        right: 0px;
    }
    .navbar-brand {
        float: none;
        width: 100%;
        display: block;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    .navbar-static-top .navbar-brand .logo {
        margin-top: 30px;
        height: auto;
        width: 270px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .landing-search {
        margin-top: 0px;
    }
    .form-inline .form-control.city {
        width: 100%;
        border-radius: 0px;
    }
    .form-group {
        margin-bottom: 2px;
    }
    .btn-primary {
        width: 100%;
        border-radius: 0px;
        text-align: left;
        margin-bottom: 80px;
        background-color: #e62332;
        padding-left: 21px;
    }
    .btn-default {
        width: 100%;
        border-radius: 0px;
        text-align: left;
        line-height: 30px;
        padding-left: 21px;
    }
    .glyphicon-search {
        float: right;
        margin-right: 5px;
    }
    .btn-default:hover,
    .btn-default:focus,
    .btn-default.focus,
    .btn-default:active,
    .btn-default.active,
    .open > .dropdown-toggle.btn-default {
        width: 100%;
    }
    .btn-group,
    .btn-group-vertical {
        width: 100%;
    }
    .fa-angle-down {
        margin-right: 9px;
    }
    .glyphicon-search {
        font-size: 20px;
        float: right;
        margin-left: 10px;
        margin-right: 8px;
        margin-top: -2px;
        color: #fff;
    }
    .footer {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 150px;
        clear: both;
    }
    .cont-footer {
        margin: 0px;
        padding: 0px;
    }
    .footer-left {
        height: 150px;
        width: 100%;
        text-align: center;
        background-repeat: no-repeat;
        ;
        background-position: center center;
    }
    .footer-logo {
        width: 250px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .footer-right {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 15px;
        text-align: center;
        width: 100%;
    }
    .footer-right p {
        text-align: center;
        width: 100%;
    }
    body.b-locator .form-inline .form-control.city {
        width: 100%;
    }

    .competition {
        margin: 20px 15px;
    }

    .top-panel {
        background-image: none;
        height: auto;

        &:after {
            content: '';
            z-index: 99;
            position: absolute;
            bottom: -25px;
            left: 50%;
            margin-left: -25px;
            width: 0;
            height: 0;
            border-left: 25px solid transparent;
            border-right: 25px solid transparent;
            border-top: 25px solid #ed1a2e;
        }

        .competition-text {

            img {
                max-width: 100%;
                margin-bottom: 30px !important;
            }

            padding-left: 5px;
            padding-top: 30px;

            p {
                font-family: $font-secondary;
                font-size: 13px;
                color: #fff;
                padding-bottom: 20px;
                margin: 0;

                &.big {
                    font-size: inherit;
                    line-height: normal;
                    padding-bottom: 20px;
                }

                &:last-child {
                    font-size: inherit;
                }

                a {
                    color: #fff;
                    font-weight: bold;
                    padding-bottom: 1px;
                    border-bottom: 1px solid #fff;

                    &:hover {
                        text-decoration: none;
                        border-bottom: 1px dotted #fff;
                    }
                }
            }
        }
    }

    .bottom-panel {
        background-color: #353535;
        padding: 45px 5px 25px 5px;
        height: auto;

        .form-group {
            width: 100%;
            float: none;
            clear: left;

            &.underline-form-group {
                overflow: hidden;
                padding-bottom: 13px;
            }

            .underline-form-item input {
                &.town-city {
                    width: 59%;
                    margin-right: 2%;
                    float: left;
                }
                &.postcode {
                    width: 39%;
                    float: left;
                }
            }

            &.form-group-submit {
                width: 100%;
                margin-right: 0;

                input {
                    background: none;
                    color: #fff;
                    border: 2px solid #1d6ec5;
                    height: 70px;
                    border-radius: 5px;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease;

                    &:hover {
                        background-color: #1d6ec5;
                    }
                }
            }
        }
    }
    .last-check {
        float: none;
    margin-left: 0px;
    }
    .denotes {
        clear: both;
        float: left;
        width: 100%;
    }

    .tooltip-icon {
        position: absolute;
        top:9px;
        left: auto;
        right: 10px;
    }
    .modal-body p {
        font-size: 12px;
    }
}
/* Tablet */

@media (min-width: 768px) and (max-width: 992px) {
    .slogan h1 {
        font-size: 18px;
    }
    .form-inline .form-control.city {
        width: 270px;
        font-size: 14px;
        padding-top: 12px;
    }
    .fa-angle-down {
        margin-top: 2px;
    }
    .btn-default {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 33px;
        color: #989898;
        background-color: #424242;
        border: none;
        height: 44px;
        width: 210px;
        text-align: left;
    }
    .btn-default:hover,
    .btn-default:focus,
    .btn-default.focus,
    .btn-default:active,
    .btn-default.active,
    .open > .dropdown-toggle.btn-default {
        width: 210px;
    }
    .share-text {
        left: -15px;
    }
    .footer {
        position: relative;
        margin-top: 50px;
        clear: both;
        width: 100%;
        height: auto;
    }
    body.b-locator .form-inline .form-control.city {
        width: 160px;
    }
    .f-locator {
        padding-left: 0px;
        padding-right: 0px;
    }
    .start {
        padding-right: 10px;
    }
    body.b-locator .glyphicon-search {
        margin-left: 0px;
        margin-right: 0px;
    }
    .nav-tabs > li.active > a.tab-red,
    .nav-tabs > li.active > a.tab-red:hover,
    .nav-tabs > li.active > a.tab-red:focus {
        color: #fff;
        cursor: default;
        background-color: #d5212e;
        border: none;
        border-bottom-color: transparent;
        border-left: 2px solid #e9e9e9;
    }
    .nav-tabs > li.active > a.tab-blue,
    .nav-tabs > li.active > a.tab-blue:hover,
    .nav-tabs > li.active > a.tab-blue:focus {
        color: #fff;
        cursor: default;
        background-color: #2e6fb5;
        border: none;
        border-bottom-color: transparent;
        border-left: 2px solid #e9e9e9;
    }
    .nav-tabs > li > a {
        margin-right: 0px;
        line-height: 1.42857143;
        border: none;
        border-radius: 0px;
        width: 120px;
        height: 60px;
        border-left: 2px solid #e9e9e9;
    }
    .nav-tabs > li > a.tab-red {
        padding-top: 20px;
        background-color: #d5212e;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }
    .nav-tabs > li > a.tab-blue {
        padding-top: 20px;
        background-color: #2e6fb5;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }
    .tab-text {
        float: left;
        margin-left: -3px;
    }
    .tab-icon {
        padding-top: 6px;
        float: right;
        margin-right: -6px;
    }
    .map-icon {
        margin-top: -15px;
        height: 26px;
    }
    .details-icon {
        margin-top: -15px;
        height: 21px;
    }
    .no-pads {
  padding-left: 10px;
  
}
    .no-pads-second {
  padding-right: 0px;
  margin-right: 0px;
  padding-left: 0px;
    margin-right: 0px;
}
.slider-controls-holder {

    margin-left: 0px;
}
.services-title {
   
    left:-70px;
    
}
.cover-up {

    top:-5px;
    left:66px;
  
}
.address {
    background-image: url(../images/detail-pointer.svg);
    background-size: 15px 24px;
    background-repeat: no-repeat;
    background-position: 20px 2px;
}
.opening {
    background-image: url(../images/detail-clock.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 16px 2px;
}
.directions {
    background-image: url(../images/detail-directions.svg);
    background-size: 19px 29px;
    background-repeat: no-repeat;
    background-position: 16px 2px;
}

    .top-panel {
        background-image: url('../uploads/competition-image-sm.jpg');
    }

    .bottom-panel {
        height: auto;

        .form-group {
            width: 352px;
            margin: 0;
            padding-right: 20px;

            &:nth-child(odd) {
                clear: left;
            }

            .underline-form-item input {
                &.town-city {
                    width: 188px;
                    margin-right: 10px;
                    float: left;
                }
                &.postcode {
                    width: 134px;
                    float: left;
                }
            }

            &.form-group-submit {
                width: 100%;
                input {
                    height: auto;
                }
            }
        }
    }

    .label-text {
    font-size:11px;
    padding-left: 15px;
    margin-top: 7px;
    float: left;
    font-weight: 400;
    color:#999999;
}
.label-text a {
    font-size:11px;
    color:#999999;
    border-bottom: 1px solid #999;
}
.denotes {
    float: right;
    font-size:11px;
    color:#999999;
    padding-right: 20px;
    padding-top: 17px;
}
.last-check {
  margin-left: 10px;

}
    .tooltip-icon {
        position: absolute;
        top:9px;
        left: 92%;
    }

}
/* Small Desktop */

@media (min-width: 993px) and (max-width: 1200px) {
    .form-inline .form-control.city {
        width: 430px;
    }
    .share-text {
        float: left;
        font-size: 15px;
        color: #fff;
        position: absolute;
        top: 13px;
        left: -19px;
    }
    body.b-locator .form-inline .form-control.city {
        width: 464px;
    }
    .tab-content > .tab-pane {
        display: block;
    }
    .fade {
        opacity: 1;
        -webkit-transition: opacity .15s linear;
        -o-transition: opacity .15s linear;
        transition: opacity .15s linear;
    }
    .comp-name {
    width:220px;
    font-size: 14px;

    }

    .top-panel {
        background-image: url('../uploads/competition-image-md.jpg');
    }

    .bottom-panel {

        .form-group {
            width: 185px;

            .underline-form-item input {
                &.town-city {
                    width: 106px;
                    margin-right: 10px;
                    float: left;
                }
                &.postcode {
                    width: 69px;
                    float: left;
                }
            }

            &.form-group-submit {
                width: 90px;
            }
        }
    }

    .tooltip-icon {
        position: absolute;
        top:9px;
        left: 86%;
    }
}
/* Large */

@media (min-width: 1201px) {
    .tab-content > .tab-pane {
        display: block;
    }
    .fade {
        opacity: 1;
        -webkit-transition: opacity .15s linear;
        -o-transition: opacity .15s linear;
        transition: opacity .15s linear;
    }
}

.tab-content > .tab-pane p {
    display: block;
    color: #fff;
}

.border-red {
    border: solid 1px red;
}





